import { memo } from "react";

import { useParams } from "react-router";

import { Stack, Typography, Grid } from "@novalabsxyz/components/core";
import { BasicPage } from "@novalabsxyz/containers/default-page";
import type { RadioRegistrationPrerequisitesSearchQuery } from "@novalabsxyz/cpi-api-client";
import {
  useGetRadioRegistrationPrerequisitesQuery,
  useGetRadioRegistrationQuery,
} from "@novalabsxyz/cpi-api-client/react";
import { pick } from "@novalabsxyz/utils/lodash-plus";

import {
  RegistrationFederatedDataCard,
  RegistrationReviewerCard,
  RegistrationMetaInfoCard,
  RegistrationBreadcrumbs,
  RegistrationLocationCard,
  RegistrationDetailsForm,
  RegistrationGatewayIpInfoCard,
} from "./components";

interface RegistrationDetailsViewParams {
  id?: string;
  [key: string]: string | undefined;
}

export const RegistrationDetailsContainer = memo(() => {
  const params = useParams<RegistrationDetailsViewParams>();

  const {
    data: registration,
    isLoading: isRegistrationLoading,
    isError: isRegistrationError,
    refetch,
  } = useGetRadioRegistrationQuery(
    {
      id: Number(params?.id),
      appendRegistrationsWithImageDuplicates: true,
      relations: ["telemetry"],
    },
    { skip: !params?.id },
  );
  const {
    data: prerequisites,
    isLoading: isPrerequisitesLoading,
    isError: isPrerequisitesError,
  } = useGetRadioRegistrationPrerequisitesQuery(
    // TODO: Use gateway serial query to get it within current component.
    // Skip specified, so empty string won't be sent.
    pick(registration, [
      "manufacturer",
      "productClass",
    ]) as RadioRegistrationPrerequisitesSearchQuery,
    { skip: !registration },
  );

  return (
    <BasicPage
      title={
        <Typography variant="h3" component="h1">
          Registration&nbsp;
          <Typography variant="h3" color="secondary" component="span">
            #{params?.id}
          </Typography>
        </Typography>
      }
      breadcrumbs={<RegistrationBreadcrumbs id={params?.id} />}
      isLoading={isRegistrationLoading || isPrerequisitesLoading}
      error={
        isRegistrationError || isPrerequisitesError
          ? "Failed to load registration details"
          : undefined
      }
      showDivider
    >
      {registration && prerequisites && (
        <Grid container spacing={5}>
          <Grid item xs={12} lg={5} xl={4} sx={{ order: { lg: 1 } }}>
            <Stack spacing={5}>
              <RegistrationReviewerCard registration={registration} />
              <RegistrationMetaInfoCard onStatusChanged={refetch} registration={registration} />
              <RegistrationLocationCard registration={registration} />
              {registration.gatewayIpInfo ? (
                <RegistrationGatewayIpInfoCard registration={registration} />
              ) : null}
              <RegistrationFederatedDataCard
                registration={registration}
                antennaModel={
                  registration.antennaModelId !== null
                    ? prerequisites.antennaModels.find(
                        ({ id }) => id === registration.antennaModelId,
                      )
                    : undefined
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={7} xl={8}>
            <RegistrationDetailsForm
              registration={registration}
              prerequisites={prerequisites}
              onSubmitted={refetch}
            />
          </Grid>
        </Grid>
      )}
    </BasicPage>
  );
});
RegistrationDetailsContainer.displayName = "RegistrationDetailsContainer";
