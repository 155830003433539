export enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}

export interface SortOptions {
  sortField: string;
  sortOrder: SortOrder;
}

export const toggleSortOrder = (sortOrder: SortOrder): SortOrder =>
  sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;

export const isSortOrder = (source: unknown): source is SortOrder =>
  typeof source === "string" && Object.values<string>(SortOrder).includes(source);

export const parseSortOrder = (source: unknown): SortOrder | undefined =>
  isSortOrder(source) ? source : undefined;

export const boolToSortOrder = (
  value?: boolean,
  trueForDescending = false,
): SortOrder | undefined => {
  switch (value) {
    case true:
      return trueForDescending ? SortOrder.Descending : SortOrder.Ascending;
    case false:
      return trueForDescending ? SortOrder.Ascending : SortOrder.Descending;
    default:
      return undefined;
  }
};
