import { memo, useCallback, useMemo } from "react";

import { Card, CardContent, IconButton, Stack, TextField } from "@novalabsxyz/components/core";
import { convertMeasurementUnitsValue, MeasurementUnit } from "@novalabsxyz/constants/measurement";
import type { AntennaModel, RadioRegistrationDetails } from "@novalabsxyz/cpi-api-client";
import { useCopyToClipboard } from "@novalabsxyz/hooks";
import { ContentCopyIcon } from "@novalabsxyz/icons";
import { changeKeys, snakeCase } from "@novalabsxyz/utils/lodash-plus";

interface FederatedDataByProductClass {
  fccId?: string;
  cbsdAntennaBeamwidth?: string;
  cbsdAntennaGain?: string;
  cbsdEirpCapability?: string;
}

const federatedDataByProductClass: { [key: string]: FederatedDataByProductClass } = {
  "FAP/pBS3101SH/SC": { fccId: "2AG32PBS3101S", cbsdAntennaBeamwidth: "65", cbsdAntennaGain: "13" },
  "FAP/pBS3101S/CA": { fccId: "2AG32PBS3101S", cbsdAntennaBeamwidth: "65", cbsdAntennaGain: "13" },
  "FAP/pBS3101S/SC": { fccId: "2AG32PBS3101S", cbsdAntennaBeamwidth: "65", cbsdAntennaGain: "13" },
  "SCO4255P-BC-A10": {
    fccId: "P27-SCO4255PA10",
    cbsdAntennaBeamwidth: "65",
    cbsdAntennaGain: "10",
    cbsdEirpCapability: "24",
  },
  "FAP/mBS31001/SC": { fccId: "2AG32MBS3100196N" },
};

interface FederatedData extends FederatedDataByProductClass {
  registration: string;
  address: string;
  userId: "YUYHM4";
  cbsdCategory: "B";
  cbsdRadioTechnology: "E_UTRA";
  cbsdType: "Base Station";
  cbsdLatitude: string;
  cbsdLongitude: string;
  cbsdHeight: string;
  cbsdHeightType: "AGL";
  cbsdAntennaAzimuth: string;
  cbsdSerialNumber: string;
  cbsdAntennaDowntilt: string;
}

export interface RegistrationFederatedDataCardProps {
  registration: RadioRegistrationDetails;
  antennaModel?: AntennaModel;
}
export const RegistrationFederatedDataCard = memo<RegistrationFederatedDataCardProps>(
  ({ registration, antennaModel }) => {
    const { isClipboardEnabled, copyToClipboard } = useCopyToClipboard();

    const data = useMemo(() => {
      const rawData: FederatedData = {
        registration: registration.id.toString(),
        address: registration.address.replaceAll(" ", "+"),
        ...federatedDataByProductClass[registration.productClass],
        userId: "YUYHM4",
        cbsdCategory: "B",
        cbsdRadioTechnology: "E_UTRA",
        cbsdType: "Base Station",
        cbsdLatitude: registration.latitude.toString(),
        cbsdLongitude: registration.longitude.toString(),
        cbsdHeight: Math.round(
          convertMeasurementUnitsValue(
            registration.heightValue,
            registration.heightUnit,
            MeasurementUnit.Meter,
          ),
        ).toString(),
        cbsdHeightType: "AGL",
        cbsdAntennaAzimuth: Math.round(registration.azimuth).toString(),
        cbsdSerialNumber: registration.cbsdSerialNumber || registration.radioSerialNumber,
        cbsdAntennaDowntilt: Math.round(-registration.elevation).toString(),
        ...(antennaModel
          ? {
              cbsdAntennaGain: Math.round(antennaModel.gainDbi).toString(),
              cbsdAntennaBeamwidth: Math.round(antennaModel.beamwidth).toString(),
            }
          : {}),
      };

      return JSON.stringify(changeKeys(rawData, snakeCase), undefined, 2);
    }, [registration, antennaModel]);

    const handleCopyButtonClick = useCallback(() => {
      void copyToClipboard(data);
    }, [data, copyToClipboard]);

    return useMemo(
      () => (
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <TextField
                name="federatedData"
                value={data}
                inputProps={{ readOnly: true }}
                multiline
                maxRows={24}
                endAdornment={
                  isClipboardEnabled ? (
                    <IconButton color="secondary" onClick={handleCopyButtonClick}>
                      <ContentCopyIcon />
                    </IconButton>
                  ) : undefined
                }
              />
            </Stack>
          </CardContent>
        </Card>
      ),
      [data, isClipboardEnabled, handleCopyButtonClick],
    );
  },
);
RegistrationFederatedDataCard.displayName = "RegistrationFederatedDataCard";
