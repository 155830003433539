import { memo } from "react";

import { Dialog, DialogHeader, DialogContent } from "@novalabsxyz/components/core";
import { useResponsive } from "@novalabsxyz/hooks";

import { RegistrationAssignReviewersForm } from "./form";

export interface RegistrationAssignReviewersDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmitted: () => void;
  title: string;
}

export const RegistrationAssignReviewersDialog = memo<RegistrationAssignReviewersDialogProps>(
  ({ open, onClose, onSubmitted, title }) => {
    const isLessThanLg = useResponsive({ to: "lg" });

    return (
      <Dialog open={open} onClose={onClose} fullScreen={isLessThanLg} maxWidth="md">
        <DialogHeader onClose={onClose}>{title}</DialogHeader>
        <DialogContent>
          <RegistrationAssignReviewersForm onSubmitted={onSubmitted} />
        </DialogContent>
      </Dialog>
    );
  },
);
RegistrationAssignReviewersDialog.displayName = "RegistrationAssignReviewersDialog";
