import { memo, useCallback, useMemo } from "react";

import { Button } from "@novalabsxyz/components/core";
import { useBoolean } from "@novalabsxyz/hooks";

import { RegistrationAssignReviewersDialog } from "./dialog";

export interface RegistrationAssignReviewersDialogTargetProps {
  onSubmitted?: () => void;
}

export const RegistrationAssignReviewersDialogTarget =
  memo<RegistrationAssignReviewersDialogTargetProps>(({ onSubmitted }) => {
    const { value: isDialogOpen, setTrue: openDialog, setFalse: closeDialog } = useBoolean();

    const handleSubmitted = useCallback(() => {
      closeDialog();
      if (onSubmitted) {
        onSubmitted();
      }
    }, [closeDialog, onSubmitted]);

    return useMemo(
      () => (
        <>
          <Button onClick={openDialog}>Assign reviewers</Button>
          <RegistrationAssignReviewersDialog
            open={isDialogOpen}
            onClose={closeDialog}
            onSubmitted={handleSubmitted}
            title="Assign reviewers"
          />
        </>
      ),
      [isDialogOpen, openDialog, closeDialog, handleSubmitted],
    );
  });
RegistrationAssignReviewersDialogTarget.displayName = "RegistrationAssignReviewersDialogTarget";
