import { memo } from "react";

import { Link as RouterLink } from "react-router-dom";

import { Stack, Breadcrumbs, Link, Typography } from "@novalabsxyz/components/core";
import { BackIcon } from "@novalabsxyz/icons";

export interface RegistrationBreadcrumbsProps {
  id?: string;
}

export const RegistrationBreadcrumbs = memo<RegistrationBreadcrumbsProps>(({ id }) => (
  <Stack direction="row" alignItems="center" spacing={0.5}>
    <BackIcon fontSize="small" color="secondary" sx={{ lineHeight: 1 }} />
    <Breadcrumbs sx={{ fontSize: "body2.fontSize" }}>
      <Link color="secondary" component={RouterLink} to="/registrations">
        CPI Registrations
      </Link>
      <Typography variant="body2" color="textPrimary">
        {id}
      </Typography>
    </Breadcrumbs>
  </Stack>
));
RegistrationBreadcrumbs.displayName = "RegistrationBreadcrumbs";
