import { memo, StrictMode, useLayoutEffect, useState } from "react";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { store } from "@novalabsxyz/cpi-client/store";
import { Notifications } from "@novalabsxyz/features/notifications";
import { browserHistory } from "@novalabsxyz/routing/history/react";
import { StylesProvider } from "@novalabsxyz/styles";

import { AppRoutes } from "./components/routes";

export const App = memo(() => {
  const [state, setState] = useState({
    action: browserHistory.action,
    location: browserHistory.location,
  });

  useLayoutEffect(() => browserHistory.listen(setState), []);

  return (
    <StrictMode>
      <StylesProvider>
        <Provider store={store}>
          <Router
            location={state.location}
            navigationType={state.action}
            navigator={browserHistory}
          >
            <AppRoutes />
            <Notifications />
          </Router>
        </Provider>
      </StylesProvider>
    </StrictMode>
  );
});
App.displayName = "App";
