import type { ReactElement } from "react";
import { memo, useCallback, useMemo } from "react";

import { Box, TableCell, TableSortLabel } from "@mui/material";

import { Tooltip } from "@novalabsxyz/components/core/tooltip";
import { SortOrder, toggleSortOrder } from "@novalabsxyz/constants/sorting";
import { startCase } from "@novalabsxyz/utils/lodash-plus";

import type { TableOnSortChange, TableColumn, TableOptions, TableRowBase } from "./types";

export interface HeadCellProps<T extends TableRowBase> {
  column: TableColumn<T>;
  options: TableOptions;
  defaultSortOrder: SortOrder;
  onSortChange?: TableOnSortChange;
}

const HeadCellNotMemoized = <T extends TableRowBase>({
  column,
  options,
  defaultSortOrder,
  onSortChange,
}: HeadCellProps<T>): ReactElement => {
  const handleSortChange = useCallback(() => {
    if (onSortChange) {
      onSortChange({
        sortField: column.id,
        sortOrder:
          options.sortField === column.id && options.sortOrder
            ? toggleSortOrder(options.sortOrder)
            : defaultSortOrder,
      });
    }
  }, [column.id, options.sortField, options.sortOrder, defaultSortOrder, onSortChange]);

  return useMemo(() => {
    const title = column.title === null ? column.title : column.title || startCase(column.id);

    return (
      <TableCell
        key={column.id}
        sx={{ whiteSpace: column.noWrap ? "nowrap" : "initial" }}
        sortDirection={
          !column.disableSort && options.sortField === column.id ? options.sortOrder : false
        }
      >
        {column.disableSort || !title ? (
          title
        ) : (
          <TableSortLabel
            active={options.sortField === column.id}
            direction={options.sortField === column.id ? options.sortOrder : SortOrder.Ascending}
            onClick={handleSortChange}
          >
            <Tooltip title={column.tooltip || undefined}>
              <Box>{title}</Box>
            </Tooltip>
          </TableSortLabel>
        )}
      </TableCell>
    );
  }, [column, options, handleSortChange]);
};
export const HeadCell = memo(HeadCellNotMemoized) as typeof HeadCellNotMemoized;
