import { Fragment, memo } from "react";

import { ImagesPreview, Link, Typography } from "@novalabsxyz/components/core";
import type { ImagesPreviewProps, Image } from "@novalabsxyz/components/core";
import { pluralize } from "@novalabsxyz/utils/text";

export interface RegistrationImage extends Image {
  registrationsWithImageDuplicates?: number[];
}

export interface RegistrationImagesPreviewProps extends ImagesPreviewProps {
  images: RegistrationImage[];
}

export const RegistrationImagesPreview = memo<RegistrationImagesPreviewProps>(
  ({ images, ...props }) => {
    const imagesWithCaptions = images.map(
      ({ registrationsWithImageDuplicates = [], ...image }) => ({
        ...image,
        isError: !!registrationsWithImageDuplicates.length,
        caption: registrationsWithImageDuplicates.length ? (
          <Typography variant="body2" color="error" align="center">
            Duplicate found in the following{" "}
            {pluralize(registrationsWithImageDuplicates.length, "registration")}:{" "}
            {registrationsWithImageDuplicates.map((id, index) => (
              <Fragment key={id}>
                {!!index && <>, </>}
                <Link
                  underline="always"
                  color="error"
                  href={`/registrations/${id}`}
                  target="_blank"
                >
                  {id}
                </Link>
              </Fragment>
            ))}
          </Typography>
        ) : undefined,
      }),
    );

    return <ImagesPreview images={imagesWithCaptions} displayCaptionInPreviewMode {...props} />;
  },
);
RegistrationImagesPreview.displayName = "RegistrationBreadcrumbs";
