import { memo } from "react";

import {
  Box,
  Dialog,
  DialogHeader,
  DialogContent,
  Button,
  Stack,
  Typography,
} from "@novalabsxyz/components/core";
import { Formik, Form, FormTextField, FormSubmitButton } from "@novalabsxyz/components/form";
import { ApprovementStatus } from "@novalabsxyz/constants/status";
import type { RadioRegistrationDetails } from "@novalabsxyz/cpi-api-client";
import { yup } from "@novalabsxyz/utils/yup";

export interface StatusChangeConfirmationFormValues {
  rejectionReason: string;
}

export interface RegistrationStatusChangeConfirmationDialogProps {
  registration: RadioRegistrationDetails;
  newStatus: ApprovementStatus;
  onClose: () => void;
  onConfirm: (params: StatusChangeConfirmationFormValues) => void;
}

export const RegistrationStatusChangeConfirmationDialog =
  memo<RegistrationStatusChangeConfirmationDialogProps>(
    ({ registration, newStatus, onClose, onConfirm }) => (
      <Dialog open onClose={onClose}>
        <DialogHeader onClose={onClose}>Change registrations status</DialogHeader>
        <DialogContent>
          <Formik
            initialValues={{
              rejectionReason: "",
            }}
            validationSchema={yup.object().shape({
              rejectionReason: yup.string().when({
                is: () => newStatus === ApprovementStatus.Rejected,
                then: (shape) => shape.required(),
              }),
            })}
            onSubmit={onConfirm}
          >
            <Form>
              <Stack spacing={5}>
                <Typography>
                  Please, confirm you want to change status of the registration #{registration.id}{" "}
                  to &quot;{newStatus}&quot;?
                </Typography>
                {newStatus === ApprovementStatus.Rejected && (
                  <FormTextField name="rejectionReason" />
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button color="error" variant="text" onClick={onClose}>
                    Cancel
                  </Button>
                  <FormSubmitButton allowClean>Confirm</FormSubmitButton>
                </Box>
              </Stack>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    ),
  );
RegistrationStatusChangeConfirmationDialog.displayName =
  "RegistrationStatusChangeConfirmationDialog";
