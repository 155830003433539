import { memo, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Stack } from "@novalabsxyz/components/core";
import {
  Formik,
  Form,
  FormSubmitButton,
  FormTextField,
  FormPasswordField,
} from "@novalabsxyz/components/form";
import { BasicPage } from "@novalabsxyz/containers/default-page";
import { cpiApiClient } from "@novalabsxyz/cpi-api-client";
import { useApiRequest } from "@novalabsxyz/features/api";
import { yup } from "@novalabsxyz/utils/yup";

interface FormValues {
  username: string;
  password: string;
}

const validationSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

const initialValues: FormValues = {
  username: "",
  password: "",
};

export const SignInContainer = memo(() => {
  const navigate = useNavigate();
  const { apiRequest } = useApiRequest();

  const handleFormSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await apiRequest(cpiApiClient.signIn, [values], { showNotificationOnUnauthorized: true });

        navigate("/registrations");
      } catch (err) {
        return;
      }
    },
    [navigate, apiRequest],
  );

  return (
    <BasicPage title="CPI Admin panel" titleAlign="center" maxWidth="sm">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        <Form>
          <Stack spacing={5}>
            <Stack spacing={3}>
              <FormTextField name="username" />
              <FormPasswordField name="password" />
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormSubmitButton>Sign In</FormSubmitButton>
            </Box>
          </Stack>
        </Form>
      </Formik>
    </BasicPage>
  );
});
SignInContainer.displayName = "SignInContainer";
