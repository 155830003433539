import { useCallback, useMemo } from "react";

import { notificationsActions } from "@novalabsxyz/features/notifications/store";
import { useDispatch } from "@novalabsxyz/store/service";

export interface UseCopyToClipboardReturnType {
  isClipboardEnabled: boolean;
  copyToClipboard: (data: string) => Promise<boolean>;
}

export const useCopyToClipboard = (): UseCopyToClipboardReturnType => {
  const dispatch = useDispatch();

  const isClipboardEnabled = useMemo(() => !!navigator?.clipboard, []);
  const copyToClipboard = useCallback(
    async (data: string) => {
      if (!isClipboardEnabled) {
        dispatch(
          notificationsActions.openWarning(
            "Unable to copy text - your browser does not support clipboard.",
          ),
        );

        return false;
      }

      try {
        await navigator.clipboard.writeText(data);

        return true;
      } catch (err) {
        if (err instanceof Error) {
          dispatch(notificationsActions.openError(`Failed to copy text - ${err.message}`));

          return false;
        }

        return false;
      }
    },
    [isClipboardEnabled, dispatch],
  );

  return { isClipboardEnabled, copyToClipboard };
};
