import { isValidElement, memo } from "react";
import type { ReactElement, ReactNodeNotNullable, ReactNode } from "react";

import { Typography, Card, CardContent, Stack, Box, Tooltip } from "@novalabsxyz/components/core";
import type { TypographyProps } from "@novalabsxyz/components/core";
import type { RadioRegistrationDetails } from "@novalabsxyz/cpi-api-client";

interface ItemProps {
  label: ReactNode;
  children?: number | string | ReactElement | null;
  typographyProps?: TypographyProps;
  tooltip?: ReactNodeNotNullable;
}
const Item = memo<ItemProps>(({ label, children, typographyProps, tooltip }) =>
  children ? (
    <Box>
      <Stack direction="row" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <Tooltip title={tooltip}>
            <Typography variant="subtitle1">{label}</Typography>
          </Tooltip>
        </Box>
        {isValidElement(children) ? (
          children
        ) : (
          <Typography {...typographyProps}>{children}</Typography>
        )}
      </Stack>
    </Box>
  ) : null,
);
Item.displayName = "Item";

export interface RegistrationGatewayIpInfoCardProps {
  registration: RadioRegistrationDetails;
}
export const RegistrationGatewayIpInfoCard = memo<RegistrationGatewayIpInfoCardProps>(
  ({ registration: { gatewayIpInfo } }) =>
    gatewayIpInfo ? (
      <Card>
        <CardContent sx={{ position: "relative" }}>
          <Stack spacing={3}>
            <Typography variant="h5">IP Info</Typography>
            <Box>
              <Stack spacing={2}>
                <Item label="IP">{gatewayIpInfo.ip}</Item>
                <Item label="Hostname">{gatewayIpInfo.hostname}</Item>
                <Item label="City">{gatewayIpInfo.city}</Item>
                <Item label="Region">{gatewayIpInfo.region}</Item>
                <Item label="Country">{gatewayIpInfo.country}</Item>
                <Item label="Location">{gatewayIpInfo.loc}</Item>
                <Item label="Organization">{gatewayIpInfo.org}</Item>
                <Item label="Postal code">{gatewayIpInfo.postal}</Item>
                <Item label="Timezone">{gatewayIpInfo.timezone}</Item>
              </Stack>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    ) : null,
);
RegistrationGatewayIpInfoCard.displayName = "RegistrationGatewayIpInfo";
