import { memo } from "react";

import { Card, CardContent, Stack } from "@novalabsxyz/components/core";
import { ApprovementStatus } from "@novalabsxyz/constants/status";
import type { RadioRegistrationDetails } from "@novalabsxyz/cpi-api-client";
import { GoogleMapsLinkTextField } from "@novalabsxyz/features/google-maps";

export interface RegistrationLocationCardProps {
  registration: RadioRegistrationDetails;
}
export const RegistrationLocationCard = memo<RegistrationLocationCardProps>(({ registration }) => (
  <Card>
    <CardContent>
      <Stack spacing={3}>
        <GoogleMapsLinkTextField
          name="registrationGPSLocationAndAddress"
          from={registration.address}
          to={registration}
        />
        {registration.status === ApprovementStatus.Approved ? (
          <>
            <GoogleMapsLinkTextField
              name="registrationAndCurrentGPSLocation"
              from={registration}
              to={registration.telemetry}
            />
            <GoogleMapsLinkTextField
              name="registrationAddressAndCurrentGPSLocation"
              from={registration.address}
              to={registration.telemetry}
            />
          </>
        ) : null}
      </Stack>
    </CardContent>
  </Card>
));
RegistrationLocationCard.displayName = "RegistrationLocation";
