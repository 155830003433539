// eslint-disable-next-line import/no-unassigned-import
import "reflect-metadata";

import { createRoot } from "react-dom/client";

import { routingServiceConfig } from "@novalabsxyz/cpi-client/config";
import { routingService } from "@novalabsxyz/routing";

import { App } from "./app";

routingService.init(routingServiceConfig);

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<App />);
