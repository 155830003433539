import { memo, useCallback, useMemo, useState } from "react";
import type { KeyboardEvent, KeyboardEventHandler } from "react";

import { emptyListPage } from "@novalabsxyz/api-client-core";
import {
  Stack,
  Box,
  IconButton,
  Grid,
  Table,
  TextField,
  Select,
  Typography,
} from "@novalabsxyz/components/core";
import type { TableColumns, SelectChangeEvent } from "@novalabsxyz/components/core";
import { DEFAULT_ROWS_PER_PAGE } from "@novalabsxyz/constants/data";
import { KeyboardKey } from "@novalabsxyz/constants/keyboard";
import type { SortOptions } from "@novalabsxyz/constants/sorting";
import { SortOrder } from "@novalabsxyz/constants/sorting";
import { ApprovementStatus } from "@novalabsxyz/constants/status";
import { BasicPage } from "@novalabsxyz/containers/default-page";
import type { RadioRegistrationWithStatus } from "@novalabsxyz/cpi-api-client";
import { useGetRadioRegistrationListQuery } from "@novalabsxyz/cpi-api-client/react";
import { RadioRegistrationStatusLabel } from "@novalabsxyz/features/radios/registration";
import { OpenInNewIcon, SearchIcon, ExclamationMarkTriangleFilledIcon } from "@novalabsxyz/icons";

import { RegistrationAssignReviewersDialogTarget } from "./components";

const registrationDetailsTableColumns: TableColumns<RadioRegistrationWithStatus> = [
  {
    id: "openInNewTab",
    title: null,
    disableSort: true,
    renderCell: ({ id }) => (
      <IconButton color="secondary" href={`/registrations/${id}`}>
        <OpenInNewIcon />
      </IconButton>
    ),
  },
  { id: "id", title: "ID" },
  { id: "gatewaySerialNumber" },
  {
    id: "radioSerialNumber",
    title: "Radio (CBSD) Serial Number",
    tooltip: "For the migrated radios CBSD Serial Number will be different from the radio's one.",
    renderCell: ({ radioSerialNumber, cbsdSerialNumber }) => (
      <>
        <Typography variant="body2">{radioSerialNumber}</Typography>
        {cbsdSerialNumber ? (
          <Typography color="warning.main" variant="body2">
            ({cbsdSerialNumber})
          </Typography>
        ) : null}
      </>
    ),
  },
  { id: "email" },
  { id: "reviewer" },
  {
    id: "status",
    renderCell: ({ status }) => (
      <RadioRegistrationStatusLabel
        status={status}
        hideTooltip
        typographyProps={{ variant: "body2" }}
      />
    ),
  },
  {
    id: "warnings",
    title: (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <ExclamationMarkTriangleFilledIcon fontSize="small" color="warning" />
        <Box>Warnings</Box>
      </Stack>
    ),
    renderCell: ({ warnings }) => (
      <Stack>
        {warnings.map((warning) => (
          <Box key={warning}>{warning}</Box>
        ))}
      </Stack>
    ),
  },
  {
    id: "createdAt",
    title: "Creation date",
    noWrap: true,
    renderCell: ({ createdAt }) => new Date(createdAt).toLocaleDateString(),
  },
  {
    id: "updatedAt",
    title: "Last modified",
    noWrap: true,
    renderCell: ({ updatedAt }) => new Date(updatedAt).toLocaleDateString(),
  },
];

const ALL_STATUS = "All";

export const RegistrationsContainer = memo(() => {
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    sortField: "id",
    sortOrder: SortOrder.Descending,
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [reviewer, setReviewer] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<ApprovementStatus | typeof ALL_STATUS>(ALL_STATUS);

  const handleSortChange = useCallback((newSortOptions: SortOptions) => {
    setSortOptions(newSortOptions);
    setPage(1);
  }, []);

  const handleRowsPerPageChange = useCallback((newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  }, []);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleSearchKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>((event) => {
    const { value } = event.target as HTMLInputElement;

    if (event.key === KeyboardKey.Enter) {
      event.preventDefault();

      setSearch(value || undefined);
      setPage(1);
    }
  }, []);

  const handleReviewerKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;

    if (event.key === KeyboardKey.Enter) {
      event.preventDefault();

      setReviewer(value || undefined);
      setPage(1);
    }
  }, []);

  const handleStatusSelect = useCallback((event: SelectChangeEvent<unknown>) => {
    setStatus(event.target.value as ApprovementStatus | typeof ALL_STATUS);
    setPage(1);
  }, []);

  const {
    isLoading,
    data = emptyListPage,
    refetch: refetchList,
  } = useGetRadioRegistrationListQuery({
    ...sortOptions,
    rowsPerPage,
    page,
    search,
    reviewer,
    status: status === ALL_STATUS ? undefined : status,
  });

  return useMemo(
    () => (
      <BasicPage title="CPI Registrations" isLoading={isLoading}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField name="reviewer" onKeyPress={handleReviewerKeyPress} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Select
              name="status"
              value={status}
              options={[
                { value: ALL_STATUS, label: ALL_STATUS },
                ...Object.values(ApprovementStatus).map((approvementStatus) => ({
                  value: approvementStatus,
                  label: <RadioRegistrationStatusLabel status={approvementStatus} hideTooltip />,
                })),
              ]}
              onChange={handleStatusSelect}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack direction="row" alignItems="flex-end" spacing={3}>
              <Box sx={{ flex: 1 }}>
                <TextField
                  name="search"
                  placeholder="Type here for searching..."
                  endAdornment={<SearchIcon />}
                  onKeyPress={handleSearchKeyPress}
                />
              </Box>
              <Box>
                <RegistrationAssignReviewersDialogTarget onSubmitted={refetchList} />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Table
              keyField="id"
              columns={registrationDetailsTableColumns}
              data={data?.data}
              isLoading={isLoading}
              options={data?.options}
              onSortChange={handleSortChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              onPageChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </BasicPage>
    ),
    [
      isLoading,
      data,
      status,
      handlePageChange,
      handleRowsPerPageChange,
      handleSortChange,
      handleSearchKeyPress,
      handleReviewerKeyPress,
      handleStatusSelect,
      refetchList,
    ],
  );
});
RegistrationsContainer.displayName = "RegistrationsContainer";
