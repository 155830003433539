import { configureStore } from "@reduxjs/toolkit";

import { cpiApiRtk } from "@novalabsxyz/cpi-api-client/react";
import { notificationsSlice } from "@novalabsxyz/features/notifications/store";
import { systemMiddleware } from "@novalabsxyz/store/middleware";

export const store = configureStore({
  reducer: {
    [cpiApiRtk.reducerPath]: cpiApiRtk.reducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cpiApiRtk.middleware, ...systemMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
