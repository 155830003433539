import { DEFAULT_ROWS_PER_PAGE } from "@novalabsxyz/constants/data";
import type { SortOptions, SortOrder } from "@novalabsxyz/constants/sorting";

export interface ListData<T> {
  data: T[];
}

export interface ListPageOptions {
  total: number;
  totalPages: number;
  page: number;
  rowsPerPage: number;
  sortField?: string;
  sortOrder?: SortOrder;
  search?: string;
}
export interface ListPage<T> extends ListData<T> {
  options: ListPageOptions;
}

export const emptyListPage = {
  data: [],
  options: {
    total: 0,
    totalPages: 0,
    page: 1,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  },
};

export interface ListPageSearchQuery extends Partial<SortOptions> {
  page?: number;
  rowsPerPage?: number;
  search?: string;
}
