import { memo, useCallback, useEffect, useMemo } from "react";

import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

import { CircularLoader, Link } from "@novalabsxyz/components/core";
import { Layout } from "@novalabsxyz/containers/layout";
import { cpiApiClient } from "@novalabsxyz/cpi-api-client";
import { useGetCurrentUserQuery } from "@novalabsxyz/cpi-api-client/react";
import { RegistrationDetailsContainer } from "@novalabsxyz/cpi-client/containers/registration-details";
import { RegistrationsContainer } from "@novalabsxyz/cpi-client/containers/registrations";
import { SignInContainer } from "@novalabsxyz/cpi-client/containers/sign-in";
import { useBoolean } from "@novalabsxyz/hooks";
import { routingService } from "@novalabsxyz/routing";

export const AppRoutes = memo(() => {
  const { value: isAppMounted, setTrue: setAppMounted } = useBoolean();

  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading: isUserLoading, data: user } = useGetCurrentUserQuery();

  const isSignInPage = useMemo(
    () => location.pathname === routingService.getRoutePathname("signIn"),
    [location.pathname],
  );

  useEffect(() => {
    if (!isUserLoading && !isAppMounted) {
      setAppMounted();

      if (isSignInPage && user) {
        navigate(routingService.getRoutePathname("signedIn"));
      } else if (!isSignInPage && !user) {
        navigate(routingService.getRoutePathname("signIn"));
      }
    }
  }, [navigate, isSignInPage, setAppMounted, isUserLoading, user, isAppMounted]);

  const signOut = useCallback(() => {
    cpiApiClient.signOut();
    navigate(routingService.getRoutePathname("signIn"));
  }, [navigate]);

  return useMemo(
    () =>
      isAppMounted ? (
        <Layout
          headerContent={
            isSignInPage ? null : (
              <Link sx={{ textTransform: "uppercase" }} onClick={signOut}>
                Sign Out
              </Link>
            )
          }
        >
          <Routes>
            <Route path={routingService.getRoutePathname("signIn")} element={<SignInContainer />} />
            <Route
              path={routingService.getRoutePathname("registrations")}
              element={<RegistrationsContainer />}
            />
            <Route
              path={routingService.getRoutePathname("registrationsItem")}
              element={<RegistrationDetailsContainer />}
            />
            <Route path="*" element={<Navigate to={routingService.getRoutePathname("signIn")} />} />
          </Routes>
        </Layout>
      ) : (
        <CircularLoader type="pageOverlay" />
      ),
    [isSignInPage, isAppMounted, signOut],
  );
});
AppRoutes.displayName = "AppRoutes";
