import { memo } from "react";
import type { ReactNode } from "react";

import type { TypographyProps } from "@novalabsxyz/components/core";
import { Box, CircularLoader, Divider, Stack, Typography } from "@novalabsxyz/components/core";
import type { Breakpoint } from "@novalabsxyz/hooks";
import { isString } from "@novalabsxyz/utils/lodash-plus";

export type BasicPageProps = {
  children?: ReactNode;
  title: ReactNode;
  titleAlign?: TypographyProps["align"];
  breadcrumbs?: ReactNode;
  maxWidth?: Breakpoint;
  isLoading?: boolean;
  error?: string;
  showDivider?: boolean;
};

export const BasicPage = memo<BasicPageProps>(
  ({
    children,
    title,
    titleAlign,
    breadcrumbs,
    maxWidth,
    isLoading = false,
    error,
    showDivider = false,
  }) => (
    <>
      <Stack sx={{ maxWidth, margin: "auto", height: 1 }} spacing={5}>
        <Box>
          {breadcrumbs}
          {isString(title) ? (
            <Typography variant="h3" component="h1" align={titleAlign}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          {showDivider && <Divider sx={{ marginBottom: 3 }} />}
          <Stack
            sx={{
              height: 1,
              ...((isLoading || error) && {
                justifyContent: "center",
                alignItems: "center",
              }),
            }}
          >
            {isLoading || error ? (
              <Typography
                sx={{
                  fontSize: "h5.fontSize",
                }}
                component="p"
              >
                {isLoading ? "Loading..." : error}
              </Typography>
            ) : (
              children
            )}
          </Stack>
        </Box>
      </Stack>
      <CircularLoader open={isLoading} type="pageOverlay" />
    </>
  ),
);
BasicPage.displayName = "BasicPage";
