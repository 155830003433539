import { memo, useCallback, useMemo } from "react";

import { Box, Divider, Grid, Stack } from "@novalabsxyz/components/core";
import type { FormikHelpers } from "@novalabsxyz/components/form";
import {
  FormNumberField,
  Formik,
  Form,
  FormTextField,
  FormSubmitButton,
  FormSelectField,
} from "@novalabsxyz/components/form";
import { MeasurementUnit } from "@novalabsxyz/constants/measurement";
import { ApprovementStatus, readOnlyApprovementStatuses } from "@novalabsxyz/constants/status";
import type {
  PatchRadioRegistrationData,
  RadioRegistrationDetails,
  RadioRegistrationPrerequisites,
} from "@novalabsxyz/cpi-api-client";
import { cpiApiClient } from "@novalabsxyz/cpi-api-client";
import { useApiRequest } from "@novalabsxyz/features/api";
import type { Full } from "@novalabsxyz/types";
import { omit } from "@novalabsxyz/utils/lodash-plus";
import { yup } from "@novalabsxyz/utils/yup";

import { RegistrationImagesPreview } from "./images-preview";

interface FormValues
  extends Full<Omit<PatchRadioRegistrationData, "reviewer" | "lastReviewedBy" | "memo">> {
  antennaPartNumber: string | null;
}

export interface RegistrationDetailsFormProps {
  registration: RadioRegistrationDetails;
  prerequisites: RadioRegistrationPrerequisites;
  onSubmitted: () => void;
}
export const RegistrationDetailsForm = memo<RegistrationDetailsFormProps>(
  ({ registration, prerequisites, onSubmitted }) => {
    const { apiRequest } = useApiRequest();

    const initialValues = useMemo(
      () => ({
        address: registration.address,
        latitude: registration.latitude,
        longitude: registration.longitude,
        heightValue: registration.heightValue,
        heightUnit: registration.heightUnit,
        azimuth: registration.azimuth,
        elevation: registration.elevation,
        antennaModelId: registration.antennaModelId,
        antennaPartNumber: registration.antennaPartNumber,
      }),
      [registration],
    );

    const validationSchema = useMemo(
      () =>
        yup.object().shape({
          address: yup.string().required(),
          latitude: yup.number().required().min(17).max(72),
          longitude: yup.number().required().min(-180).max(-66),
          heightValue: yup.number().required().positive(),
          heightUnit: yup.string().required().oneOf(Object.values(MeasurementUnit)),
          azimuth: yup.number().required().min(0).lessThan(360),
          elevation: yup.number().required().min(-30).max(30),
          antennaModelId: yup
            .number()
            .required()
            .positive()
            .integer()
            .nullable()
            .label("Antenna Model"),
          antennaPartNumber: yup.string().nullable(),
        }),
      [],
    );

    const handleFormSubmit = useCallback(
      async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        try {
          await apiRequest(cpiApiClient.patchRadioRegistration, [
            { id: registration.id, ...omit(values, ["antennaPartNumber"]) },
          ]);

          resetForm({ values });
          onSubmitted();
        } catch (err) {
          return;
        }
      },
      [apiRequest, registration.id, onSubmitted],
    );

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={handleFormSubmit}
      >
        <Form
          status={
            [
              ApprovementStatus.Verification,
              ApprovementStatus.Approved,
              ApprovementStatus.Archived,
            ].includes(registration.status)
              ? "readOnly"
              : undefined
          }
        >
          <Stack spacing={5}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormTextField name="address" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormNumberField name="latitude" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormNumberField name="longitude" />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item md={8} sm={7}>
                      <Grid container alignItems="center" spacing={3}>
                        <Grid item sm={9} xs={8}>
                          <FormNumberField name="heightValue" />
                        </Grid>
                        <Grid item sm={3} xs={4}>
                          <FormSelectField
                            name="heightUnit"
                            label="Units"
                            options={Object.values(MeasurementUnit).map((unit) => ({
                              value: unit,
                            }))}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={4} sm={5}>
                      <RegistrationImagesPreview
                        images={registration.heightImages}
                        label="Height Images"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4} alignItems="center">
                    <Grid item md={8} sm={7}>
                      <FormNumberField name="azimuth" label="Azimuth angle in degrees" />
                    </Grid>
                    <Grid item md={4} sm={5}>
                      <RegistrationImagesPreview
                        images={registration.azimuthImages}
                        label="Azimuth Angle Image"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4} alignItems="center">
                    <Grid alignItems="center" item md={8} sm={7}>
                      <FormNumberField name="elevation" label="Elevation angle in degrees" />
                    </Grid>
                    <Grid item md={4} sm={5}>
                      <RegistrationImagesPreview
                        images={registration.elevationImages}
                        label="Elevation Angle Image"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={registration.antennaPartNumber ? 6 : 12}>
                  <FormSelectField
                    name="antennaModelId"
                    label="Antenna Model"
                    options={prerequisites.antennaModels.map(
                      ({ id, manufacturer, antennaName, model }) => ({
                        value: id,
                        label:
                          [
                            manufacturer,
                            antennaName,
                            ...(model !== antennaName ? [model] : []),
                          ].join(", ") || id,
                      }),
                    )}
                    optionForUndefined={{ value: "--" }}
                    defaultValue="--"
                  />
                </Grid>
                {prerequisites.antennaModels.length > 1 && (
                  <>
                    {registration.antennaPartNumber ? (
                      <Grid item xs={12} sm={6}>
                        <FormTextField
                          name="antennaPartNumber"
                          label="Antenna Part Number (from user)"
                          readOnly
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={4} alignItems="center">
                        <Grid item sm={6}>
                          <RegistrationImagesPreview
                            images={registration.modelPlateImages}
                            label="Antenna Product Badge Images"
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <RegistrationImagesPreview
                            images={registration.overallImages}
                            label="Overall Antenna Image"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            {!readOnlyApprovementStatuses.includes(registration.status) ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FormSubmitButton>Update</FormSubmitButton>
              </Box>
            ) : null}
          </Stack>
        </Form>
      </Formik>
    );
  },
);
RegistrationDetailsForm.displayName = "RegistrationDetailsForm";
