import { memo, useMemo, useCallback } from "react";

import { Stack, TextField, IconButton } from "@novalabsxyz/components/core";
import type { TextFieldProps } from "@novalabsxyz/components/core";
import { useCopyToClipboard } from "@novalabsxyz/hooks";
import { ContentCopyIcon, OpenInNewIcon } from "@novalabsxyz/icons";
import { isString } from "@novalabsxyz/utils/lodash-plus";

export type GoogleMapsLocation = string | { latitude: number | string; longitude: number | string };
export interface GoogleMapsLinkTextFieldProps extends Pick<TextFieldProps, "name" | "label"> {
  from: GoogleMapsLocation;
  to?: GoogleMapsLocation;
}

const getLocationString = (location: GoogleMapsLocation): string =>
  isString(location) ? location.replaceAll(" ", "+") : `${location.latitude},${location.longitude}`;

export const GoogleMapsLinkTextField = memo<GoogleMapsLinkTextFieldProps>(
  ({ from, to, ...props }) => {
    const { isClipboardEnabled, copyToClipboard } = useCopyToClipboard();

    const link = useMemo(
      () =>
        `https://maps.google.com/maps/${
          to
            ? `dir/${getLocationString(from)}/${getLocationString(to)}`
            : `place/${getLocationString(from)}`
        }`,
      [from, to],
    );

    const handleCopyButtonClick = useCallback(() => {
      void copyToClipboard(link);
    }, [link, copyToClipboard]);

    return useMemo(
      () => (
        <TextField
          {...props}
          value={link}
          inputProps={{ readOnly: true, sx: { textOverflow: "ellipsis" } }}
          endAdornment={
            <Stack direction="row" spacing={1}>
              {isClipboardEnabled && (
                <IconButton color="secondary" onClick={handleCopyButtonClick}>
                  <ContentCopyIcon />
                </IconButton>
              )}
              <IconButton color="secondary" href={link} target="_blank" rel="noreferrer noopener">
                <OpenInNewIcon />
              </IconButton>
            </Stack>
          }
        />
      ),
      [link, props, isClipboardEnabled, handleCopyButtonClick],
    );
  },
);
GoogleMapsLinkTextField.displayName = "GoogleMapsLinkTextField";
