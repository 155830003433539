import { memo, useCallback, useEffect, useMemo, useState } from "react";
import type { ChangeEvent, ReactElement, NamedExoticComponent } from "react";

import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

import { DefaultTableRowsPerPageOptions } from "@novalabsxyz/constants/data";
import { SortOrder } from "@novalabsxyz/constants/sorting";

import { BodyRow, SingleCellBodyRow } from "./body-row";
import { HeadCell } from "./head-cell";
import type {
  TableRowBase,
  TableOnRowExpand,
  TableOnSortChange,
  TableColumns,
  TableData,
  TableOptions,
  TableOnPageChange,
  TableOnRowsPerPageChange,
} from "./types";

export type {
  TableRowBase,
  TableOnRowExpand,
  TableOnPageChange,
  TableOnRowsPerPageChange,
  TableOnSortChange,
  TableColumns,
  TableData,
  TableOptions,
};

export interface TableProps<T extends TableRowBase> {
  columns: TableColumns<T>;
  data: TableData<T>;
  isLoading?: boolean;
  options: TableOptions;
  keyField?: keyof T;
  rowsPerPageOptions?: number[];
  defaultSortOrder?: SortOrder;
  disableSort?: boolean;
  disablePagination?: boolean;
  noDataMessage?: string;
  onPageChange?: TableOnPageChange;
  onRowsPerPageChange?: TableOnRowsPerPageChange;
  onSortChange?: TableOnSortChange;
  onRowExpand?: TableOnRowExpand<T>;
  ExpandedRow?: NamedExoticComponent<T>;
}

const TableNotMemoized = <T extends TableRowBase>({
  columns,
  data,
  isLoading = false,
  options,
  keyField = "id",
  rowsPerPageOptions = DefaultTableRowsPerPageOptions,
  defaultSortOrder = SortOrder.Ascending,
  disableSort = false,
  disablePagination = false,
  noDataMessage = "There is no data matching your criterions. Please update your filters",
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
  onRowExpand,
  ExpandedRow,
}: TableProps<T>): ReactElement => {
  const [expandedRow, setExpandedRow] = useState<T | undefined>(undefined);

  // Reset expanded row if no longer present in items list.
  useEffect(() => {
    if (expandedRow) {
      const isExpandedRowAbsent = !data.some((row) => row[keyField] === expandedRow[keyField]);

      if (isExpandedRowAbsent) {
        setExpandedRow(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (onRowExpand) {
      onRowExpand(expandedRow);
    }
  }, [expandedRow, onRowExpand]);

  const handlePageChange = useCallback(
    (_: unknown, value: number) => {
      if (onPageChange) {
        onPageChange(value + 1);
      }
    },
    [onPageChange],
  );

  const handleRowsPerPageChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (onRowsPerPageChange) {
        onRowsPerPageChange(parseInt(value, 10));
      }
    },
    [onRowsPerPageChange],
  );

  return useMemo(
    () => (
      <TableContainer component={Paper}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {ExpandedRow && <TableCell />}
              {columns.map((columnOptions) => (
                <HeadCell
                  key={columnOptions.id}
                  column={{
                    ...columnOptions,
                    disableSort: disableSort || columnOptions.disableSort,
                  }}
                  options={options}
                  defaultSortOrder={defaultSortOrder}
                  onSortChange={onSortChange}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || (!data.length && !!noDataMessage) ? (
              <SingleCellBodyRow>{isLoading ? "Loading..." : noDataMessage}</SingleCellBodyRow>
            ) : (
              data.map((dataItem) => (
                <BodyRow
                  key={dataItem[keyField]}
                  data={dataItem}
                  columns={columns}
                  expanded={!!expandedRow && dataItem[keyField] === expandedRow[keyField]}
                  onExpand={setExpandedRow}
                  ExpandedRow={ExpandedRow}
                />
              ))
            )}
          </TableBody>
        </MuiTable>
        {!disablePagination && (
          <TablePagination
            rowsPerPageOptions={onRowsPerPageChange ? rowsPerPageOptions : [options.rowsPerPage]}
            component="div"
            count={options.total}
            rowsPerPage={options.rowsPerPage}
            page={options.page - 1}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </TableContainer>
    ),
    [
      columns,
      data,
      isLoading,
      options,
      keyField,
      expandedRow,
      rowsPerPageOptions,
      defaultSortOrder,
      disableSort,
      disablePagination,
      noDataMessage,
      handlePageChange,
      onRowsPerPageChange,
      handleRowsPerPageChange,
      onSortChange,
      ExpandedRow,
    ],
  );
};
export const Table = memo(TableNotMemoized) as typeof TableNotMemoized;
