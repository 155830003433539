import { cpiApiClient } from "@novalabsxyz/cpi-api-client";
import type { RoutesConfig, RoutingServiceConfig } from "@novalabsxyz/routing";
import { browserHistory } from "@novalabsxyz/routing/history/react";

declare module "@novalabsxyz/routing" {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface RouteOverrides {
    registrations: true;
    registrationsItem: true;
  }
}

const routes: Omit<RoutesConfig, "home" | "signedIn" | "signOut"> = {
  signIn: { pathname: "/sign-in", label: "Sign In" },
  registrations: { pathname: "/registrations", label: "CPI Registrations" },
  registrationsItem: { pathname: "/registrations/:id", label: "Registration" },
};

export const routingServiceConfig: RoutingServiceConfig = {
  routes: {
    ...routes,
    home: routes.signIn,
    signOut: routes.signIn,
    signedIn: routes.registrations,
  },
  redirect: (path) => browserHistory.push(path),
  signOut: () => cpiApiClient.signOut(),
};
