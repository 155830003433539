import { memo, useCallback } from "react";

import { Card, CardContent, Stack, Box, Grid } from "@novalabsxyz/components/core";
import { Formik, Form, FormSubmitButton, FormTextField } from "@novalabsxyz/components/form";
import type { FormikHelpers } from "@novalabsxyz/components/form";
import { ApprovementStatus, readOnlyApprovementStatuses } from "@novalabsxyz/constants/status";
import { cpiApiClient } from "@novalabsxyz/cpi-api-client";
import type { RadioRegistrationDetails } from "@novalabsxyz/cpi-api-client";
import { useApiRequest } from "@novalabsxyz/features/api";
import { yup } from "@novalabsxyz/utils/yup";

interface FormValues {
  reviewer: string;
  memo: string;
}

const validationSchema = yup.object().shape({
  reviewer: yup.string().required().max(100),
  memo: yup.string().max(2000),
});

export interface RegistrationReviewerCardProps {
  registration: RadioRegistrationDetails;
}

export const RegistrationReviewerCard = memo<RegistrationReviewerCardProps>(({ registration }) => {
  const { apiRequest } = useApiRequest();

  const handleFormSubmit = useCallback(
    async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        await apiRequest(cpiApiClient.patchRadioRegistration, [{ id: registration.id, ...values }]);

        resetForm({ values });
      } catch (err) {
        return;
      }
    },
    [apiRequest, registration.id],
  );

  return (
    <Card>
      <CardContent>
        <Formik
          initialValues={{
            reviewer: registration.reviewer || "",
            memo: registration.memo || "",
          }}
          validationSchema={
            readOnlyApprovementStatuses.includes(registration.status) ? undefined : validationSchema
          }
          onSubmit={handleFormSubmit}
        >
          <Form
            status={
              [ApprovementStatus.Verification, ApprovementStatus.Archived].includes(
                registration.status,
              )
                ? "readOnly"
                : undefined
            }
          >
            <Stack spacing={5}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormTextField
                      name="reviewer"
                      disabled={registration.status === ApprovementStatus.Approved}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField name="memo" multiline minRows={4} maxRows={12} />
                  </Grid>
                </Grid>
              </Box>
              {ApprovementStatus.Archived !== registration.status ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FormSubmitButton>Update</FormSubmitButton>
                </Box>
              ) : null}
            </Stack>
          </Form>
        </Formik>
      </CardContent>
    </Card>
  );
});

RegistrationReviewerCard.displayName = "RegistrationReviewerCard";
