import { useCallback, memo, useMemo } from "react";

import { Box, Stack, Grid, Typography } from "@novalabsxyz/components/core";
import {
  Formik,
  Form,
  FormTextField,
  FormSubmitButton,
  FormNumberField,
} from "@novalabsxyz/components/form";
import { cpiApiClient } from "@novalabsxyz/cpi-api-client";
import { useApiRequest } from "@novalabsxyz/features/api";
import { yup } from "@novalabsxyz/utils/yup";

interface FormValues {
  reviewers: string;
  limit: number;
}

export interface RegistrationAssignReviewersFormProps {
  onSubmitted: () => void;
}

export const RegistrationAssignReviewersForm = memo<RegistrationAssignReviewersFormProps>(
  ({ onSubmitted }) => {
    const { apiRequest } = useApiRequest();

    const handleFormSubmit = useCallback(
      async ({ reviewers, ...rest }: FormValues) => {
        try {
          await apiRequest(cpiApiClient.assignReviewersToRadioRegistrations, [
            {
              reviewers: reviewers
                .split(",")
                .map((reviewer) => reviewer.trim())
                .filter((reviewer) => !!reviewer),
              ...rest,
            },
          ]);

          onSubmitted();
        } catch (err) {
          return;
        }
      },
      [apiRequest, onSubmitted],
    );

    return useMemo(
      () => (
        <Formik
          initialValues={{
            reviewers: "",
            limit: 10,
          }}
          validationSchema={yup.object().shape({
            reviewers: yup.string().required(),
            limit: yup.number().required().integer().min(1).max(100),
          })}
          onSubmit={handleFormSubmit}
        >
          <Form>
            <Stack spacing={5}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>
                    Please, enter available reviewers, separated with comma. Each of them will be
                    assigned with up to limited amount of registrations for review.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormTextField name="reviewers" />
                </Grid>
                <Grid item xs={12}>
                  <FormNumberField name="limit" integer positive />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FormSubmitButton>Submit</FormSubmitButton>
              </Box>
            </Stack>
          </Form>
        </Formik>
      ),
      [handleFormSubmit],
    );
  },
);
RegistrationAssignReviewersForm.displayName = "RegistrationAssignReviewersForm";
