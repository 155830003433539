import { isValidElement, memo, useCallback, useMemo, useState } from "react";
import type { ReactElement, ReactNodeNotNullable, ReactNode } from "react";

import {
  Typography,
  Card,
  CardContent,
  Divider,
  Stack,
  Select,
  CircularLoader,
  Box,
  Tooltip,
} from "@novalabsxyz/components/core";
import type { SelectChangeEvent, TypographyProps } from "@novalabsxyz/components/core";
import {
  ApprovementStatus,
  readOnlyApprovementStatuses,
  writeableApprovementStatuses,
} from "@novalabsxyz/constants/status";
import type { RadioRegistrationDetails } from "@novalabsxyz/cpi-api-client";
import { cpiApiClient } from "@novalabsxyz/cpi-api-client";
import { useGetRadioStatusQuery } from "@novalabsxyz/cpi-api-client/react";
import { useApiRequest } from "@novalabsxyz/features/api";
import { RadioRegistrationStatusLabel } from "@novalabsxyz/features/radios/registration";
import { ExclamationMarkTriangleFilledIcon, YesNoIcon } from "@novalabsxyz/icons";
import { assert } from "@novalabsxyz/utils/assert";

import type { StatusChangeConfirmationFormValues } from "./status-change-confirmation-dialog";
import { RegistrationStatusChangeConfirmationDialog } from "./status-change-confirmation-dialog";

interface ItemProps {
  label: ReactNode;
  children?: number | string | ReactElement | null;
  typographyProps?: TypographyProps;
  tooltip?: ReactNodeNotNullable;
}
const Item = memo<ItemProps>(({ label, children, typographyProps, tooltip }) =>
  children ? (
    <Box>
      <Stack direction="row" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <Tooltip title={tooltip}>
            <Typography variant="subtitle1">{label}</Typography>
          </Tooltip>
        </Box>
        {isValidElement(children) ? (
          children
        ) : (
          <Typography {...typographyProps}>{children}</Typography>
        )}
      </Stack>
    </Box>
  ) : null,
);
Item.displayName = "Item";

export interface RegistrationMetaInfoCardProps {
  registration: RadioRegistrationDetails;
  onStatusChanged: (status: ApprovementStatus) => void;
}
export const RegistrationMetaInfoCard = memo<RegistrationMetaInfoCardProps>(
  ({ registration, onStatusChanged }) => {
    const { apiRequest } = useApiRequest();

    const [newStatus, setNewStatus] = useState<ApprovementStatus | undefined>();

    const { data: radioStatus, isLoading: isRadioStatusLoading } = useGetRadioStatusQuery({
      radioSerialNumber: registration.radioSerialNumber,
    });

    const handleStatusChange = useCallback((event: SelectChangeEvent<unknown>) => {
      setNewStatus(event.target.value as ApprovementStatus);
    }, []);

    const handleStatusChangeCancellation = useCallback(() => {
      setNewStatus(undefined);
    }, []);

    const handleStatusChangeConfirmation = useCallback(
      async (values: StatusChangeConfirmationFormValues) => {
        assert(newStatus, "Status is not defined.");

        try {
          await apiRequest(cpiApiClient.updateRadioRegistrationStatus, [
            {
              id: registration.id,
              status: newStatus,
              ...values,
            },
          ]);

          setNewStatus(undefined);
          onStatusChanged(newStatus);
        } catch (err) {
          return;
        }
      },
      [apiRequest, registration.id, newStatus, onStatusChanged],
    );

    return useMemo(
      () => (
        <>
          <Card>
            <CardContent sx={{ position: "relative" }}>
              <CircularLoader open={isRadioStatusLoading} type="itemOverlay" />
              <Stack spacing={2}>
                <Select
                  name="status"
                  readOnly={readOnlyApprovementStatuses.includes(registration.status)}
                  value={registration.status}
                  options={(readOnlyApprovementStatuses.includes(registration.status)
                    ? [registration.status]
                    : writeableApprovementStatuses
                  ).map((status) => ({
                    value: status,
                    label: <RadioRegistrationStatusLabel status={status} hideTooltip />,
                  }))}
                  onChange={handleStatusChange}
                />
                {radioStatus &&
                radioStatus.latestRegistration?.status !== ApprovementStatus.Approved ? (
                  <Item label="Was CPI approved">
                    <YesNoIcon value={radioStatus.hasArchivedRegistrations} fontSize="small" />
                  </Item>
                ) : null}
                <Item label="Last reviewed by">{registration.lastReviewedBy}</Item>
                <Item label="Rejection reason">{registration.rejectionReason}</Item>
                {registration.warnings.length ? (
                  <Item
                    label={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <ExclamationMarkTriangleFilledIcon fontSize="small" color="warning" />
                        <Box>Warnings</Box>
                      </Stack>
                    }
                  >
                    <Stack>
                      {registration.warnings.map((warning) => (
                        <Box key={warning}>{warning}</Box>
                      ))}
                    </Stack>
                  </Item>
                ) : null}
                <Divider />
                <Item label="Registration ID">{registration.id}</Item>
                <Item label="Email">{registration.email}</Item>
                <Item label="Manufacturer">{registration.manufacturer}</Item>
                <Item label="Product Class">{registration.productClass}</Item>
                <Item label="Gateway Serial Number">{registration.gatewaySerialNumber}</Item>
                <Item label="Radio Serial Number">{registration.radioSerialNumber}</Item>
                <Item
                  label="CBSD Serial Number"
                  tooltip="For the migrated radios CBSD Serial Number will be different from the radio's one."
                  typographyProps={{ color: "warning.main" }}
                >
                  {registration.cbsdSerialNumber}
                </Item>
                <Item label="Created at">
                  {new Date(registration.createdAt).toLocaleDateString("en")}
                </Item>
                <Item label="Last modified by user at">
                  {new Date(registration.modifiedByUserAt).toLocaleDateString("en")}
                </Item>
                <Item label="Last modified at">
                  {new Date(registration.updatedAt).toLocaleDateString("en")}
                </Item>
              </Stack>
            </CardContent>
          </Card>
          {newStatus && (
            <RegistrationStatusChangeConfirmationDialog
              newStatus={newStatus}
              registration={registration}
              onClose={handleStatusChangeCancellation}
              onConfirm={handleStatusChangeConfirmation}
            />
          )}
        </>
      ),
      [
        registration,
        newStatus,
        isRadioStatusLoading,
        radioStatus,
        handleStatusChange,
        handleStatusChangeCancellation,
        handleStatusChangeConfirmation,
      ],
    );
  },
);
RegistrationMetaInfoCard.displayName = "RegistrationMetaInfo";
